html {
  background: $color-panel;
  width: 100%;
  height: auto !important;
  min-height: 100%;
}

body {
  background: $color-panel;
  font-size: 16px;
}

input, select { font-size: 100%; }

.content {
  @media only screen and (max-width: 375px) {
    margin-top: 74px !important;
  }
  @media only screen and (min-width: 376px) and (max-width: 575px) {
    margin-top: 86px !important;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 100px !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin-top: 114px !important;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1980px) {
    margin-top: 125px !important;
  }
  @media only screen and (min-width: 1981px){
    margin-top: 138px !important;
  }
}

.form-control {
  font-size: 16px;
}