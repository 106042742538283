@import "abstracts/variables";


.text-third {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $text-third;
}

.font-weight-500 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  color: #ffffff;
}

.font-black-0 {
  color: #000000 !important;
}

.font-light-black {
  color: #777777 !important;
}

.custom-spinner {
  color: red;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}


.product-title {
  font-size: 26px;
  font-weight: normal;
  color: $color-primary;
}

.product-price, .product-content {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.product-content {
  text-align: right;
}

.product-specs {
  font-size: 20px;
  text-align: left;
}

.product-category {
  font-size: 20px;
  font-weight: normal;
  color: $color-light-black;
}

.product-brand {
  font-size: 18px;
  font-weight: bold;
  color: $color-light-black;
  margin-bottom: -4px;
}

.product-text {
  font-size: 20px;
  text-align: justify;
}

.color-white {
  background-color: white;
}

.calculator-input {
  font-size: 18px;
  input {
    font-size: 18px;
  }
  label {
    font-size: 18px;
  }
}


.card-synopsis {
  background-color: #fafffa;
  border-color: $color-primary;
}

.card-body-synopsis {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-header-synopsis {
  background-color: $color-primary;
  border-radius: 9px 9px 0 0;
  color: #ffffff;
  font-size: 24px;
  border: none;
  border-radius: 9px 9px 0 0 !important;
}

.card-title-synopsis {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 0px;
  line-height: 30px;
}

.card-footer-synopsis {
  background-color: $color-primary !important;
  border-radius: 0 0 9px 9px !important;
  opacity: 0.5;
}

.card-footer-text-synopsis {
  opacity: 1;
  color: #000000 !important;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.card-detail {
  background-color: #fafffa;
}

.card-header-detail {
  border-radius: 9px 9px 0 0;
  padding: 0px;
  .card-title {
    padding: 15px 20px;
    background-color: $color-primary;
    color: white;
  }
  img {
    border-radius: 9px 9px 0 0;
  }
}

.card-body-detail {
  button, a {
    font-size: 18px;
  }
}

.background-transparent {
  background-color: transparent;
}

.calculator {
  .btn {
    font-size: 14px;
  }
  i {
    font-size: 18px;
  }
  .form-floating>.form-control {
    height: calc(4rem + 2px);
    padding-top: 2.2rem;
    border-radius: 5px;
  }
}
