.button {
  &-primary {
    background: $color-primary;
  }
  &-secondary {
    background: #ffffff;
    border: 1px solid #72c7b6;
    box-sizing: border-box;
    border-radius: 6px;
  }
  &-signIn {
    @media only screen and (max-width: 575px) {
      padding-left: 0 !important;
    }
    @media only screen and (min-width: 575px) {
      padding-bottom: 0 !important;
    }
  }
}
