.drc-footer {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  background: #ffffff;
  &__box {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: $color-primary;
    border-radius: 99px 99px 0px 0px;
  }
}
