// COLORS
$color-primary: #72C7B6;
$color-full-black: #000000;
$color-light-black: #777777;
$color-background: #000000;

// FONTS SIZE
$f-size-12: 12px;
$f-size-13: 13px;
$f-size-14: 14px;
$f-size-15: 15px;
$f-size-16: 16px;
$f-size-18: 18px;
$f-size-20: 20px;
$f-size-22: 22px;
$f-size-25: 25px;

// FONTS WEIGHT
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-900: 900;
