.drc-header {
  height: 10vh !important;
  background: #ffffff !important;
  @media only screen and (max-width: 575px) {
    height: 13vh !important;
  }
}
.custom {
  &-nav-link {
    position: static;
    height: 24px;
    left: 0px;
    right: 479px;
    top: calc(50% - 24px / 2);
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: #414141 !important;
    @media only screen and (min-width: 575px) {
      padding-left: 2.5rem !important;
      padding-right: 2.5rem !important;
    }
  }
  &-nav__bar {
    @media only screen and (max-width: 575px) {
      background-color: #f7f7f7 !important;
    }
  }
}

nav{
  box-shadow: 2px 2px 5px grey;
  img {
    @media only screen and (max-width: 375px) {
      width: 140px;
      height: 40px;
    }
    @media only screen and (min-width: 376px) and (max-width: 575px) {
      width: 180px;
      height: 52px;
    }
    @media only screen and (min-width: 576px) and (max-width: 768px) {
      width: 230px;
      height: 66px;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      width: 280px;
      height: 80px;
    }
    @media only screen and (min-width: 1025px) and (max-width: 1980px) {
      width: 320px;
      height: 91px;
    }
    @media only screen and (min-width: 1981px){
      width: 360px;
      height: 104px;
    }
  }
}

.form-select {
  border : none;
}

.form-select:focus {
  border : none;
  box-shadow: none;
}

.collapse {
  @media only screen and (max-width: 375px) {
    margin-top: 54px !important;
  }
  @media only screen and (min-width: 376px) and (max-width: 575px) {
    margin-top: 66px !important;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 80px !important;
  }
  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin-top: 94px !important;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1980px) {
    margin-top: 105px !important;
  }
  @media only screen and (min-width: 1981px){
    margin-top: 118px !important;
  }
}